<template>
  <v-card
  max-width="100%"
  class="my-8"
  outlined
  >
    <v-card-title>
      Third Party Carriers
    </v-card-title>
    <v-alert
      v-if="company.data_sources.some(i => i.uses_shopify_labels)"
      type="info"
      outlined
      dense
      class="mx-6"
      >
    One or more of your stores are set to use Shopify labels. This section is primarily for stores that doesn't use Shopify Labels. You do not need to setup additional carriers. Edit settings for Shopify Labels in Stores.
    </v-alert>
    <v-card 
      v-for="(carrier, index) in company.carriers.filter(c => c.type !== 'shopify')"
      :key="`carrier-${index}`"
      outlined
      round
      class="ma-4 pa-4"
      >
      <v-row>
        <v-col cols="4">
          {{ carrier.name }}
        </v-col>
        <v-col cols="4">
          {{ carrier.type }}
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" outlined @click="remove_carrier(carrier.id)">
            <!-- <v-icon left>mdi-minus</v-icon> -->
            Remove
          </v-btn>
          <v-btn color="primary" outlined @click="edit_carrier(carrier.id)" class="ml-8">
            <!-- <v-icon left>mdi-minus</v-icon> -->
            Edit
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-container
      class="text-center"
      >
      <v-dialog
        v-model="dialog"
        width="500"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          >
          <v-icon left>mdi-plus</v-icon>
          Add carrier
        </v-btn>
        </template>
        <v-card
          :loading="saving_carrier"
          >
          <v-form 
            @submit.prevent="save_carrier" 
            ref="carrier_form">
            <v-card-title class="text-h5 grey lighten-2">
              Add Carrier
            </v-card-title>
            <v-card-text 
              class="mt-4"
              v-if="saving_carrier || editing_carrier || (get_plan && company.carriers.length < get_plan.max_carriers) "
              >
            <v-text-field
                round
                outlined
                v-model="new_carrier.name"
                label="Nickname"
                placeholder="ACME Postnord account"
                :rules="[v => !!v || 'Item is required']"
                >
              </v-text-field>
              <v-select
                v-model="new_carrier.type"
                @change="new_carrier = { name: new_carrier.name, type: new_carrier.type, id: new_carrier.id, ...carriers[new_carrier.type]}"
                label="Type"
                item-text="display_name"
                item-value="type"
                :items="carrier_types"
                :rules="[v => !!v || 'Item is required']"
                />
              <template v-if="new_carrier.type === 'unifaun'">
                <v-text-field
                round
                outlined
                v-model="new_carrier.api_key"
                label="Combined ID"
                placeholder="E2DRTSZ3WFUIHRSR-UKX2537DPCF2APSAEQKTHN2U"
                @blur="trim_key"
                :rules="[v => !!v || 'Item is required',
                        v => v && v.length === 41 || 'Wrong format. Make sure to get the entire combined id which consist of <ID 16 letters>-<SECRET ID 24 letters>']"
                />
                <v-text-field
                round
                outlined
                type="number"
                v-model="new_carrier.sender_id"
                label="Sender-ID"
                placeholder="1"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                v-model="new_carrier.contents"
                label="Default contents"
                placeholder="ACME T-shirts"
                :rules="[v => !!v || 'Item is required']"
                />
              </template>
              <template v-else-if="new_carrier.type === 'postnord'">
                <v-select
                v-model="new_carrier.issuerCode"
                label="Postnord customer country *"
                item-text="name"
                item-value="id"
                :items="[
                  {name: 'Postnord Sweden', id: 'Z12'},
                  {name: 'Postnord Denmark', id: 'Z11'},
                  {name: 'Postnord Norway', id: 'Z13'},
                  {name: 'Postnord Finland', id: 'Z14'},
                ]"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.partyIdentification.partyId"
                label="Postnord customer number *"
                placeholder="01234567"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.nameIdentification.name"
                label="Name *"
                placeholder="John Doe / ACME Company"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.nameIdentification.companyName"
                label="Company Name"
                placeholder="ACME Company"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.nameIdentification.careOfName"
                label="C/O Name"
                placeholder="Big warehouse"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.address.streets[0]"
                label="Street *"
                placeholder="Main road 123"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.address.streets[1]"
                label="Street 2"
                placeholder=""
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.address.postalCode"
                label="Postal Code *"
                placeholder="12345"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.address.city"
                label="City *"
                placeholder="Stockholm"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-select
                v-model="new_carrier.party.address.countryCode"
                label="Country *"
                item-text="name"
                item-value="id"
                :items="[
                  {name: 'Sweden', id: 'SE'},
                  {name: 'Denmark', id: 'DK'},
                  {name: 'Norway', id: 'NO'},
                  {name: 'Finland', id: 'FI'},
                ]"
                :rules="[v => !!v || 'Item is required']"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.contact.contactName"
                label="Contact name"
                placeholder="John Doe"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.contact.emailAddress"
                label="Contact email"
                placeholder="john.doe@example.com"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.contact.phoneNo"
                label="Contact phone"
                placeholder="+4631123123"
                />
                <v-text-field
                round
                outlined
                type="text"
                v-model="new_carrier.party.contact.smsNo"
                label="Contact SMS number"
                placeholder="+46703123123"
                />
                <v-text-field
                round
                outlined
                v-if="$appMode === 'postnord'"
                v-model="eori_number"
                label="EORI / customs number"
                />
              </template>

            </v-card-text>
            <v-card-text
              v-else
              class="mt-4"
              >
              Your current plan does not allow more carrier accounts to be added. Please upgrade plan.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                text
                type="submit"
                :loading="saving_carrier"
                v-if="saving_carrier || editing_carrier || (get_plan && company.carriers.length < get_plan.max_carriers) "
              >
                {{ editing_carrier && 'Save' || 'Connect'}}
              </v-btn>
            </v-card-actions>
          </v-form>
      </v-card>
    </v-dialog>

    </v-container>
      
</v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { uid } from 'uid'

export default {
  components: {
    // ToolTip,
    
    
  },
  props: {
    
  },
  data(){
    return {
      editing_carrier: false,
      dialog: false,
      connection_error: false,
      saving_carrier: false,
      new_carrier: {
        name: '',
        type: '',
        id: '',
      },
      carriers: {
        unifaun: {
          api_key: '',
          sender_id: '',
          contents: '',
        },
        postnord: {
          "issuerCode": "",
          "partyIdentification": {
            "partyId": "",
            "partyIdType": "160"
          },
          "party": {
            "nameIdentification": {
              "name": "",
              "companyName": "",
              "careOfName": ""
            },
            "address": {
              "streets": [
                "", ""
              ],
              "postalCode": "",
              // "placeName": "string",
              // "state": "string",
              "city": "",
              "countryCode": ""
            },
            "contact": {
              "contactName": "",
              "emailAddress": "",
              "phoneNo": "",
              "smsNo": ""
            }
          }
        },
      },
      carrier_types: [
        { display_name: 'Unifaun / nShift', type: 'unifaun' },
        { display_name: 'Postnord', type: 'postnord' },
      ],
      eori_number: '',
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    }),
    // ...mapFields([
    //   'warehouse.company.products_refresh_interval',
    //   'warehouse.company.order_bucket_size',
    //   'warehouse.company.days_valid_inventory_count',
    //   'warehouse.company.check_inventory_threshold',
    //   ]),
  },
  watch: {
    dialog: function(v){
      // saving_carrier || (get_plan && !editing_carrier && company.carriers.length < get_plan.max_carriers)
      // console.log('dialog watcher', this.company.carriers.length, this.get_plan.max_carriers, this.editing_carrier, this.saving_carrier)
      if(!v) {
        console.log('watcher')
        this.editing_carrier = false
        this.new_carrier = {
        name: '',
        type: '',
        // api_key: '',
        // sender_id: '',
        // contents: '',
        id: '',
      }
      this.$refs.carrier_form.resetValidation()
      this.connection_error = false
      }
    }
  },
  methods: {
    trim_key(){
      this.new_carrier.api_key = this.new_carrier.api_key.replace(/ /g, '')
    },
    edit_carrier(id){
      const carrier = this.company.carriers.find(c => c.id === id)
      this.editing_carrier = true
      this.new_carrier = { ...carrier }
      this.dialog = true
    },
    remove_carrier(id){
      console.log(id);
      const is_used = this.company.data_sources
        .filter(ds => ds.shipping_map
          .some(s => s.sf_carrier_account_id === id)
          )
        .map(ds => ds.name)
      if(is_used.length) return this.$store.commit('app/SET_SNACK_BAR', `This carrier account is being used in shipping maps for ${is_used.toString()}. Remove these shipping routings before removing the carrier account.`)
      const carriers = this.company.carriers
      const index = carriers.findIndex(c => c.id === id)
      if(index === -1) return 
      carriers.splice(index, 1)
      this.$store.commit('warehouse/SET_COMPANY', { carriers })
      this.$store.dispatch('warehouse/save_company', ['carriers'])
    },
    async save_carrier(){
      if(!this.$refs.carrier_form.validate()) return this.$store.commit('app/SET_SNACK_BAR', 'Something is not properly filled out, please scroll up and make sure everything seems correct!')
      this.saving_carrier = true
      if(this.eori_number) this.update_data_source({ eori_number: this.eori_number })
      const carriers = this.company.carriers
      const id = this.new_carrier.id || uid()
      if(this.new_carrier.id){ carriers[carriers.findIndex(c => c.id === id)] = { ...this.new_carrier } }
      else carriers.push({ ...this.new_carrier, id })
      this.$store.commit('warehouse/SET_COMPANY', { carriers })
      await this.$store.dispatch('warehouse/save_company', ['carriers'])
      
      await this.$store.dispatch('shipping/get_carrier_partners', id)
        .then(() => {
          this.saving_carrier = false
          return this.dialog = false
          })
        .catch(async () => {
          this.saving_carrier = false
          this.$store.commit('app/SET_SNACK_BAR', 'Failed to connect. Please check credentials.')
          const carriers = this.company.carriers.filter(c => c.id !== id)
          this.$store.commit('warehouse/SET_COMPANY', { carriers })
          await this.$store.dispatch('warehouse/save_company', ['carriers'])
        })
    },
    update_data_source(set){
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.company.data_sources[0].id,
        ...set
      })
    },
   
  },
  mounted(){
    if(this.$route.query.showDialog) this.dialog = true
    else this.dialog = false
    if(process.env.VUE_APP_MODE === 'postnord'){
      this.carrier_types = this.carrier_types.filter(a => a.type === 'postnord')
      this.eori_number === this.company.data_sources[0].eori_number
      // this.new_carrier.name = 'Postnord'
    }
  }
}
</script>